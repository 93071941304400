class GloRSLoyaltyModalSuccessCard extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		//var modal = document.getElementById('modalCenter');
		//UIkit.modal(modal).show();
	}

	afterLoad() {
		this.$el = $(this);

		$('#modal-loyalty-success').on('beforeshow', () => { 
			const coins = $('#modal-loyalty-success').find('.coin-gain .coin-gained').text();
			if (!coins || coins == '0') {
				window.location.reload();
			}
		});
		$('#modal-loyalty-success').on('hidden', () => window.location.reload());
	}

	beforeUpdate() {
	}

	afterUpdate() {
	}

	unload() {
	}
}

!customElements.get('bat-card-glorsloyaltymodalsuccess') &&
	customElements.define('bat-card-glorsloyaltymodalsuccess', GloRSLoyaltyModalSuccessCard);
